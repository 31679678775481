




















































import { Component } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { Inject, logger } from '../../../support'

import { AbstractModuleUi } from '../../abstract/ui'
import { CertificatesListModule } from '../CertificatesList.contracts'
import { ISelenaFormService, SelenaFormServiceType } from '../../../front/shared/services/selenaForms'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<CertificatesListModuleUi>({
  name: 'CertificatesList'
})
export class CertificatesListModuleUi extends AbstractModuleUi<CertificatesListModule> {
  @Inject(SelenaFormServiceType)
  protected readonly selenaFormService!: ISelenaFormService

  /**
   * Determines loading state.
   */
  public isLoading: boolean = false

  /**
   *
   */
  public certificates: AnyObject[] = []

  /**
   * Store intercepted error from submitted form.
   */
  public error: Error | null = null

  /**
   * Created lifecycle hook.
   */
  public async created () {
    setTimeout(async () => {
      this.certificates = await this.getCertificates()
      // Little hack here - in debug mode (storybook) we have to wait until selenaFormsService wil obtain auth token.
    }, this.$attrs.debug ? 1000 : 0)
  }

  /**
   * Fetch certificates
   */
  private async getCertificates (): Promise<AnyObject[]> {
    this.isLoading = true
    try {
      return await this.selenaFormService.getCertificates()
    } finally {
      this.isLoading = false
    }
  }
}

export default CertificatesListModuleUi
